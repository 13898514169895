import { Injectable, Injector } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http'
import { ApiService } from '../services/api.service';

@Injectable({
    providedIn: 'root'
})
export class ApiInterceptorsService implements HttpInterceptor {

    constructor(private injector: Injector) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const apiToken = localStorage.getItem('token')
        // const branchId = localStorage.getItem('branchId') as string
        if (apiToken) {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${apiToken}`,
                branchId:  localStorage.getItem('branchId') as string
            })

            const clonedReq = req.clone({
                headers
                // setHeaders: {
                //     Authorization: `Bearer ${apiToken}`
                // }
            })
            return next.handle(clonedReq)
        }
        return next.handle(req)
    }
}
