import { PageNotFoundComponent } from './website/shared/page-not-found/page-not-found.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
    {
        path: 'admin',
        loadChildren: () => import('./admin-panel/admin-panel.module')
            .then(mod => mod.AdminPanelModule)
    },
    {
        path: 'sub-admin',
        loadChildren: () => import('./sub-admin-panel/sub-admin-panel.module')
            .then(mod => mod.SubAdminPanelModule)
    },
    {
        path: 'instructor',
        loadChildren: () => import('./instructor-panel/instructor-panel.module')
            .then(mod => mod.InstructorPanelModule)
    },
    {
        path: 'company',
        loadChildren: () => import('./company-panel/company-panel.module')
            .then(mod => mod.CompanyPanelModule)
    },
    {
        path: '',
        loadChildren: () => import('./website/website.module')
            .then(mod => mod.WebsiteModule)
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        data: { message: 'From ROOT' }
    }
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutes { }
