<nav class="cg">
    <div class="container">
        <div class="logo">
            <img src="/assets/images/logo.png" alt="Logo">
        </div>
        <ul>
            <li *ngIf="isAdmin">
                <a [routerLink]="['/admin/dashboard']">My Portal</a>
            </li>
            <li *ngIf="isSubAdmin">
                <a [routerLink]="['/sub-admin/dashboard']">My Portal</a>
            </li>
            <li  *ngIf="isCompany">
                <a [routerLink]="['/company/dashboard']">My Portal</a>
            </li>
            <li *ngIf="!isAuthenticated">
                <a [routerLink]="['/login']">Login</a>
            </li>
        </ul>
    </div>
</nav>
