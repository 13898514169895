export const environment = {
    production: false
}

export const apis = {
    baseUrl: 'https://gym-desk-api.codegenio.com/api',
    // baseUrl: 'https://localhost:3010/api',
    googleApiKey: 'AIzaSyCq-_3Fqmw-4Y6tdKFToydCYdVaprzHTjA',
    googleCaptchaSiteKey: ''
}

export const socialLoginUrls = {
    google: `${apis.baseUrl}/public/login/google`,
    facebook: `${apis.baseUrl}/public/login/facebook`
}

export const appURL = 'https://gym-desk.codegenio.com'
