import { Event, Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { ThemeService } from './services/theme.service'
import { Observable } from 'rxjs'
import { ApiService } from './services/api.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    isLoading: boolean

    constructor(
        private route: Router,
        private themeService: ThemeService,
        private api: ApiService
    ) {
        this.route.events.subscribe((routerEvent: Event) => {
        })
    }

    ngOnInit() {
        this.themeService.setTheme('default')
        const branchId = localStorage.getItem('branchId')
        if (branchId != null) {
            this.api.updateBranchId(branchId)
        }

        if(this.api.isAuthenticated()) {
            this.api.branchesList = this.api.user.branches
        }
    }
}
